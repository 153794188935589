import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as Theme from "../Theme";
import CollapseRow from "./CollapseRow";
import Constanst from "../constants";
import CopyrightLinks from "./CopyrightLinks";
import StaticImage from "./StaticImage";
import * as Icons from "./Icon";

function SocialLinks(props) {
  const links = [
    {
      image: "../../images/facebook.svg",
      link: Constanst.socialFacebook,
      name: "facebook",
    },
    {
      image: "../../images/instagram.svg",
      link: Constanst.socialInstagram,
      name: "instagram",
    },
    {
      image: "../../images/line.svg",
      link: Constanst.socialLine,
      name: "line",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        marginBottom: 10,
        alignSelf: "center",
        ...props.style,
      }}
    >
      {links.map((link, idx) => (
        <a
          href={link.link}
          target="_blank"
          key={idx}
          style={{ marginLeft: idx !== 0 ? "18px" : "0" }}
          rel="noreferrer"
        >
          {link.image && (
            <img src={`${link.image}`} alt={link.name} width="39" height="39" />
          )}
          {link.icon && <link.icon size={30} />}
        </a>
      ))}
    </div>
  );
}

const items = [
  {
    label: "關於 INFO",
    url: "",
    children: isSmall => (
      <Fragment>
        <Link
          to="/about"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          品牌概念
        </Link>
        <Link
          to="/about"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          感官服務流程
        </Link>
        <Link
          to="/quote"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          印刷製作諮詢
        </Link>
        <Link
          to="/about"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          聯絡感官
        </Link>
      </Fragment>
    ),
  },
  {
    label: "作品 WORKS",
    url: "",
    children: isSmall => (
      <Fragment>
        <Link
          to="/works/?label=數位印刷"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          數位印刷
        </Link>
        <Link
          to="/works/?label=展覽畫冊"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          展覽畫冊
        </Link>
        <Link
          to="/works/?label=畢製專刊"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          畢製專刊
        </Link>
        <Link
          to="/works/?label=包裝結構"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          包裝結構
        </Link>
      </Fragment>
    ),
  },
  {
    label: "線上訂購",
    url: "",
    children: isSmall => (
      <Fragment>
        <Link
          to="/products"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          全部商品
        </Link>
        <Link
          to="/products?tab=客製化貼紙"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          客製化貼紙
        </Link>
        <Link
          to="/products?tab=客製化專區"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          客製化專區
        </Link>
        <Link
          to="/products?tab=設計師專區" // XXX
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          設計師專區
        </Link>
      </Fragment>
    ),
  },
  {
    label: "常見問題",
    url: "",
    children: isSmall => (
      <Fragment>
        <Link
          to="/faq"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          購物指南
        </Link>
        <Link
          to="/faq"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          印刷須知
        </Link>
        <Link
          to="/faq"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          常見問題
        </Link>
        <Link
          to="/faq"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          條款聲明
        </Link>
      </Fragment>
    ),
  },
  {
    label: "觀點 BLOG",
    url: "",
    children: isSmall => (
      <Fragment>
        <Link
          to="/articles/印件分享"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          印件分享
        </Link>
        <Link
          to="/articles/印刷知識"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          印刷知識
        </Link>
        <Link
          to="/articles/紙張材料"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          紙張材料
        </Link>
        <Link
          to="/articles/專欄課程"
          className="nav-item"
          style={{ justifyContent: isSmall ? "center" : "flex-start" }}
        >
          專欄課程
        </Link>
      </Fragment>
    ),
  },
  // {
  //   label: "訂閱 SUBSCRIBE",
  //   url: "",
  //   children: (
  //     <Fragment>
  //       <Link to="/articles" className="nav-item">
  //         感官印刷線上課
  //       </Link>
  //     </Fragment>
  //   ),
  // },
  {
    label: "聯絡我們",
    url: "",
    children: () => (
      <Fragment>
        <p>
          <a
            style={{ textDecoration: "none", color: "#6b6b6b" }}
            aria-label="SensationsPrint email"
            href="mailto:service@ssprint.com.tw"
          >
            service@ssprint.com.tw
          </a>
        </p>
        <p>
          <span
            style={{ textDecoration: "none", color: "#6b6b6b" }}
            aria-label="SensationsPrint phone"
          >
            02-2736-6566
          </span>
        </p>
        <p>
          <a
            style={{ textDecoration: "none", color: "#6b6b6b" }}
            aria-label="SensationsPrint address"
            href="https://maps.app.goo.gl/e7yXBVw4arZz36Sf7"
            target="_blank"
            rel="noreferrer"
          >
            新北市中和區板南路496號3樓
          </a>
        </p>
        <p>週一至週五上午10點至下午7點（例假日休息）</p>
      </Fragment>
    ),
  },
];

class Footer extends Component {
  render() {
    let { extraStyle = "", pathname } = this.props;

    return (
      <Wrapper extraStyle={extraStyle}>
        {/* large screen */}
        <Info>
          <Block flex="0.3" css="margin-bottom: 60px;">
            <img
              onClick={() => navigate("/")}
              src="../../images/logo-vertical.png"
              alt="logo"
              height="auto"
              width={100}
              style={{ cursor: "pointer" }}
            />
          </Block>

          {/* ignore the last one (contact us) */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 3,
              alignSelf: "stretch",
            }}
          >
            <Block
              flex="4"
              css="display: flex; flex-direction: row; flex-wrap: nowrap;"
            >
              {items
                .filter((_, idx, arr) => idx < arr.length - 1)
                .map((item, idx) => (
                  <Block key={idx} flex="1" style={{ maxWidth: 150 }}>
                    <p className="title">{item.label}</p>
                    {item.children()}
                  </Block>
                ))}
            </Block>
            {typeof window !== "undefined" &&
              window.innerWidth > Theme.breakpoints.lg && (
                <div className="copyright" style={{ textAlign: "left" }}>
                  <CopyrightLinks />
                </div>
              )}
          </div>

          {/*   the last one (contact us) */}
          <Block flex="0.3">
            <div
              className="contact-wrapper"
              style={
                typeof window !== "undefined" &&
                window.innerWidth > Theme.breakpoints.lg
                  ? { marginLeft: 60 }
                  : { alignSelf: "flex-end" }
              }
            >
              <Block style={{ marginLeft: 40 }}>
                <p className="title">{items[items.length - 1].label}</p>
                {items[items.length - 1].children()}
              </Block>
              <div style={{ marginTop: 40, alignSelf: "flex-end" }}>
                <SocialLinks />
                {typeof window !== "undefined" &&
                  window.innerWidth < Theme.breakpoints.lg && (
                    <div
                      className="copyright"
                      style={{ padding: 0, paddingBottom: 14 }}
                    >
                      <CopyrightLinks />
                    </div>
                  )}
              </div>
            </div>
          </Block>
        </Info>

        {/* small screen */}
        <MobileWrapper>
          <div css="flex-direction:row;justify-content:center;margin-bottom:10px;">
            <img
              src="../../images/logo-vertical.png"
              alt="logo"
              height={100 * (263 / 270)}
              width={100}
            />
          </div>
          <Block css="align-items:center;margin-bottom:20px;">
            {items
              .filter((_, idx, arr) => idx < arr.length - 1)
              .map((item, idx) => (
                <CollapseRow item={item} key={idx}>
                  <Block css="text-align:center;">{item.children(true)}</Block>
                </CollapseRow>
              ))}
          </Block>
          {/*   the last one (contact us) */}
          <Block flex="1" css="text-align:center;">
            <p style={{ fontSize: 16, color: "#5a5a5a" }}>
              {items[items.length - 1].label}
            </p>
            {items[items.length - 1].children(true)}
          </Block>
          <Block>
            <SocialLinks />
            <div className="copyright">
              <CopyrightLinks />
            </div>
          </Block>
        </MobileWrapper>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: ${Theme.colors.sub};
  min-height: 250px;
  width: 100vw;
  padding: 20px 0px 0px 0px;
  color: ${Theme.colors.main};
  display: flex;
  flex-direction: column;
  z-index: 1;

  & .copyright {
    padding: 15px;
    color: black;
    background-color: ${Theme.colors.sub};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    align-self: stretch;

    @media screen and (max-width: ${Theme.breakpoints.lg}px) {
      /* margin-right: 80px; */
    }
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    display: none;
  }
  ${props => props.extraStyle};
`;

let Info = styled.div`
  display: flex;
  padding: 40px 40px;
  justify-content: space-between;
  flex: 1;

  @media screen and (max-width: ${Theme.breakpoints.lg}px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    display: none;
  }
`;

let Block = styled.div`
  margin: 0px 10px;
  /* max-width: 350px; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-grow: ${props => props.flex || 0};

  ${props => props.css}

  & > .contact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > div {
      flex-direction: column;
    }
    @media screen and (max-width: ${Theme.breakpoints.lg}px) {
      flex-direction: row-reverse;
      margin-top: 40px;
    }
  }

  & > .nav-item {
    margin-bottom: 7px;
    font-size: 14px;
    color: #6b6b6b;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  & > .title {
    font-size: 14px;
    color: #3c3c3c;
    line-height: 15px;
  }

  & > p {
    color: #6b6b6b;
    line-height: 15px;
  }
  & > .company-name {
    color: #37c491;
    font-size: 30px;
  }

  & > .contact {
    font-size: 22px;
  }

  & > .menu {
    flex-grow: 1;
  }

  & > .row-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    min-width: 200px;
    margin-bottom: 20px;
    align-items: stretch;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${Theme.breakpoints.xs}px) {
    display: none;
  }
`;

export default connect(null, ActionCreator)(Footer);

export { SocialLinks };
