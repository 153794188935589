const Settings = {
  "debug": false,
  "domain": "localhost:8000",
  "maintenance": false,
  "webUrl": "https://www.sensationsprint.com.tw/",
  "apiUrl": "https://api.sensationsprint.com",
  "mediaHost": "",
  "s3Host": "https://s3-ap-northeast-1.amazonaws.com/stg.hecafe.com.tw",
  "facebookAppId": "1276459929127208",
  "googleClientId": "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
  "googleAnalyticsId": "G-3QNQG85WKS",
  "gtmId": "",
  "googleTagId": "",
  "fbPixelId": "469678884180607",
  "facebookMessagerAppId": "632096150745219",
  "facebookMessagerPageId": "250292708979271",
  "userType": "aws",
  "slsApi": {
    "host": "http://localhost:3000"
  },
  "productSpecUrl": "https://prod-sensationsprint-com-v2.s3-ap-northeast-1.amazonaws.com/product",
  "calcPriceUrl": "https://eculture.hiprint.store/v1/product/calc-price?client_id=sensationsprint",
  "socialFacebook": "https://www.facebook.com/sensationsprint",
  "socialInstagram": "https://www.instagram.com/sensationsprint/",
  "socialLine": "https://page.line.me/ssprint?openQrModal=true",
  "breakpoints": {
    "sm": 430,
    "md": 576,
    "lg": 744,
    "xl": 1024,
    "xxl": 1280
  },
  "supportLogistics": [
    "hct",
    "self_pick",
    "xdelivery",
    "payuni"
  ],
  "fontSize": {
    "xl": 28,
    "lg": 24,
    "md": 20,
    "sm": 16,
    "xs": 14
  },
  "colors": {
    "text": "#000000",
    "background": "#ffffff",
    "border": "#edeef1",
    "highlight": "#ff5520"
  },
  "buttons": {
    "background": {
      "normal": {
        "primary": "#ff5520",
        "secondary": "#000000",
        "outline": "transparent"
      },
      "hover": {
        "primary": "#d94011",
        "secondary": "#40444c",
        "outline": "#ffe7d9"
      },
      "disable": {
        "primary": "#FFF4ED",
        "secondary": "#EDEEF1",
        "outline": "#EDEEF1"
      }
    },
    "text": {
      "normal": {
        "primary": "#ffffff",
        "secondary": "#ffffff",
        "outline": "#ff5520"
      },
      "hover": {
        "primary": "#ffffff",
        "secondary": "#ffffff",
        "outline": "#ff5520"
      },
      "disable": {
        "primary": "#FF9D73",
        "secondary": "#8E95A2",
        "outline": "#6B7280"
      }
    },
    "padding": {
      "lg": "16px 24px",
      "md": "10px 16px"
    },
    "height": {
      "lg": 56,
      "md": 44
    }
  },
  "env": "prod",
  "revClientId": "sensationsprint",
  "revAuthServiceApi": "https://auth.hiprint.store/v4",
  "revBlogDashboardUrl": "https://revtel-blog.netlify.app",
  "revBlogServiceApi": "https://blog.revtel-api.com",
  "revStorageHost": "https://storage.hiprint.store/v1",
  "revServiceApi": "https://discovery.revtel-api.com/v1/service",
  "revJsonStorageHost": "https://jstorage.hiprint.store/v1",
  "calcHost": "https://eculture.hiprint.store/v1"
};

module.exports = Settings;
